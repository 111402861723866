// Normalize Styles
@import '../../node_modules/normalize.css/normalize.css';

dark = #4C2C69
light = #7FDBFF
transition-ease = color 0.2s ease
myFont = "Arvo", serif;


vhcenter()
	display flex
	align-items center
	justify-content center

.stuff
	width 100%
	height 100vh
	background-color dark
	vhcenter()
	color lighten(dark,40)
	font-size 6vw
	.sidewalk
		vhcenter()
		font-family myFont
		flex-direction column
		justify-content space-around
		height 100vh
		.hello
			.hi
				color lighten(dark,50)
				color lighten(dark,50)
				text-align center
		a.contact
			color lighten(dark,30)
			text-decoration none
			transition transition-ease
			&:hover
				text-decoration underline
				color lighten(dark,40)
				transition transition-ease	
				
		